import React from "react";
import "./Service.css";
import airplane from "../assests/icons/world.png";
import satelliteDish from "../assests/icons/satellite-dish.png";
const Service = () => {
  return (
    <div className='container'>
      <h3 className='heading text-center'>Services</h3>
      <hr />
      <div className='row'>
        <div className='col-md-6'>
          <i class='fa fa-solid fa-boxes-stacked service-icon'></i>
          <h6 className='service-heading'>Global Wholesale Trading</h6>
          <p className='service-description'>
            With our footprints across the globe, we can bring you the latest
            Mobility products at the most competitive prices, while managing
            swift turnaround times for deliveries and maintaining the highest
            standards of quality and compliance, as demanded by our Customers.
          </p>
        </div>
        <div className='col-md-6'>
          <img src={airplane} className='icon' alt='airplane' />
          <h6 className='service-heading'>Logistics and Shipping</h6>
          <p className='service-description'>
            Efficient and reliable logistics and shipping are critical to the
            success of international trade in consumer electronics. Our
            comprehensive logistics and shipping services are designed to ensure
            that your products reach their destination promptly and in perfect
            condition.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Service;
