import React from "react";
import "./Footer.css";
import { Col, Row } from "react-bootstrap";
import iTrade from "../assests/iTrade-logo.png";

const Footer = () => {
  return (
    <footer className='footer'>
      <Row>
        <Col md={3} className='firstCol'>
          <img src={iTrade} className='footer-logo' alt='iTrade Bharat Logo' />
          <p>
            We are one of the leading Mobile devices, Mobile Accessories and
            Electronics distributors, wholesalers and traders in the region with
            over 20 years of experience in the industry.
          </p>
        </Col>
        <div className='col-md-3'>
          <h3>Useful Links</h3>

          <a href=''>Home</a>

          <a href=''>About</a>

          <a href=''>Products</a>

          <a href=''>Services</a>

          <a href=''>Contact</a>
        </div>
        <Col md={3}>
          <section aria-labelledby='head-office'>
            <h3 id='head-office'>Head Office</h3>
            <div className='location-info'>
              <i className='fa fa-solid fa-location-dot' aria-hidden='true'></i>
              <p>
                125/127, Kazi Sayed Street, Navjivan Building, Mezzanine Floor,
                Masjid Bunder(West), Mumbai - 400003
              </p>
            </div>
            <div className='contact-info'>
              <i className='fa fa-solid fa-phone' aria-hidden='true'></i>
              <a href='tel:+918657585994'>+91 86575 85994</a>
            </div>
            <div className='email-info'>
              <i className='fa fa-regular fa-envelope' aria-hidden='true'></i>
              <a href='mailto:info@itradebharat.com'>info@itradebharat.com</a>
            </div>
          </section>
        </Col>
        <Col md={3}>
          <section aria-labelledby='newsletter'>
            <h3 id='newsletter'>Newsletter</h3>
            <p>Subscribe to our newsletter to stay updated.</p>
            <form>
              <label htmlFor='email' className='sr-only'>
                Email Address
              </label>
              <input
                type='email'
                id='email'
                placeholder='Enter your Email Address'
                aria-label='Enter your Email Address'
                required
              />
              <button type='submit'>Subscribe →</button>
            </form>
          </section>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
