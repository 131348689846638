import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSwipeable } from "react-swipeable";
import "./Carousel.css";
import slide5 from "../assests/iphone15-slide5.jpg";
import slide17 from "../assests/slide17.jpg";
import slide14 from "../assests/slide14.jpg";
import slide16 from "../assests/slide16.jpg";

const Carousel = () => {
  const [index, setIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    },
    onSwipedRight: () => {
      setIndex((prevIndex) =>
        prevIndex === 0 ? slides.length - 1 : prevIndex - 1
      );
    },
  });

  const slides = [
    {
      src: slide5,
      title: "MOBILE PHONES",
      description: "You Desire",
    },
    {
      src: slide17,
      title: "COME, PARTNER WITH US",
      description: "Earn benefits and help us revolutionize the supply chain",
    },
    {
      src: slide14,
      title: "STORAGE SOLUTIONS",
      description: (
        <>
          Your Trusted <br /> Warehouse Facility
        </>
      ),
    },
    {
      src: slide16,
      title: "GLOBAL PRESENCE",
      description: "In Mobility Sector",
    },
  ];

  return (
    <div
      {...handlers}
      id='carouselExampleControls'
      className='carousel slide'
      data-bs-ride='carousel'
      style={{ height: "60vh", position: "relative" }}
    >
      <div className='carousel-inner'>
        {slides.map((slide, idx) => (
          <div
            className={`carousel-item ${index === idx ? "active" : ""}`}
            key={idx}
          >
            <div className={`text-content ${idx === 1 ? "small" : ""}`}>
              <h3 className='z-index-n1 carousel-h3'>{slide.title}</h3>
              <p className='z-index-n1 carousel-p'>{slide.description}</p>
            </div>
            <img
              src={slide.src}
              className='carousel-img d-block w-100'
              alt={`Slide ${idx + 1}`}
            />
          </div>
        ))}
      </div>
      {/* Left and right controls/arrows */}
      <button
        className='carousel-control-prev'
        type='button'
        data-bs-target='#carouselExampleControls'
        data-bs-slide='prev'
      >
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
      </button>
      <button
        className='carousel-control-next'
        type='button'
        data-bs-target='#carouselExampleControls'
        data-bs-slide='next'
        onClick={() => setIndex(index === slides.length - 1 ? 0 : index + 1)}
      >
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
      </button>
      {/* Custom Indicators */}
      <div className='carousel-indicators-custom'>
        {slides.map((_, idx) => (
          <span
            key={idx}
            className={idx === index ? "dot active" : "dot"}
            onClick={() => setIndex(idx)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
