import React from "react";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className='contact-us-contatiner'>
      <div className='row'>
        <div className='col-md-6'>
          <h4 className='contact-us-heading'>Contact Us</h4>
          <p className='contact-us-p'>
            We value your feedback and would love to hear from you. Please feel
            free to get in touch with us.
          </p>
          <hr />
          <div className='contact-us-address'>
            <div className='icon-box-feature'>
              <i class='fa fa-light fa-location-dot'></i>
            </div>
            <p>
              <strong>iTrade Bharat Address</strong> <br />
              125/127, Kazi Sayed Street, Navjivan Building, Mezzanine Floor,
              Masjid Bunder(West), Mumbai - 400003
            </p>
          </div>
          <div className='contact-us-address'>
            <div className='icon-box-feature'>
              <i class='fa fa-light fa-phone'></i>
            </div>
            <p>
              <strong>Phone</strong> <br />
              <a href='tel:+918657585994'>+91 86575 85994</a>
            </p>
          </div>
          <div className='contact-us-address'>
            <div className='icon-box-feature'>
              <i class='fa fa-regular fa-envelope'></i>
            </div>
            <p>
              <strong>E-mail</strong> <br />
              <a href='mailto:info@itradebharat.com'>info@itradebharat.com</a>
            </p>
          </div>
        </div>
        <div className='col-md-6'>
          <h6>Contact Us</h6>
          <form>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>Name</label>
              <input
                type='text'
                className='form-control'
                placeholder='Enter Name'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputEmail1'>Email address</label>
              <input
                type='email'
                className='form-control'
                id='exampleInputEmail1'
                aria-describedby='emailHelp'
                placeholder='Enter email'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputPassword1'>Password</label>
              <input
                type='password'
                className='form-control'
                id='exampleInputPassword1'
                placeholder='Password'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='exampleInputComment'>Comment or Message</label>
              <textarea
                className='form-control'
                placeholder='Message'
                rows='5'
              ></textarea>
            </div>

            <button type='submit' className='w-100 mt-3 btn btn-primary'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
